import styles from './Form.module.css';
import {ethers} from 'ethers';
import { useEffect, useState } from 'react';
import axios from 'axios';
export const Form = () => {
    
    const [wallet , setWallet] = useState('');
    const [error, setError] = useState(false);
    const [pool, setPool] = useState({
        "hteBalance": "reading.....",
        "Antbalance": "reading....."
    });
    const [token, setToken] = useState('10 Antex Points - (ANT-P)');

    const handleWallet = (e) => {
        if(!ethers.isAddress(wallet)){
            setError(true);
        } else {
            setError((e) => {
                return false;
            });
        };
        setWallet(e.target.value);
    }
    const selectCoinHandler = (e) => {
        console.log(e.target)
        setToken(e.target.value)
    }
    const submitHandler = async (e) => {
        e.preventDefault();
        if(!ethers.isAddress(wallet)){
            setError(true);
            return;
        }
        console.log("clicked")
    

        if(token === '10 Antex Points - (ANT-P)'){
            axios.post(`https://api.hinox.io/claim`, {
                wallet : wallet
            }).then((res) => {
                console.log(res.data)
                alert(res.data.message)
        })
        } else if (token === '0.2 Hepton Testnet - (HTE)'){
            axios.post(`https://api.hinox.io/claimHTE`, {
                wallet : wallet
            }).then((res) => {
                console.log(res.data)
                alert(res.data.message)
        })
        }
        
}

    useEffect(() => {
        if(!ethers.isAddress(wallet)){
            setError(true);
        } else {
            setError((e) => {
                return false;
            });
        };


            axios.get('https://api.hinox.io/getFaucetBalance').then((res) => {
            setPool(res.data)
        })
       
    //     const getData = () => {
    //     fetch(`https://api.hinox.io/getFaucetBalance`, {mode: 'no-cors'})
    //     .then((res) => res.json())
    //     .then((data) => {
    //         setPool(data);
    //         console.log(data)
    //     });
    // }
    //     getData()
        
    }, [error, wallet]);
  return (
   <form >
     <h3 className={styles.welcome}>Welcome to Hinox Faucet</h3> <br></br>

            <label className={styles.pools}> ANT-P Pool : {Number(pool.Antbalance).toFixed(2)}</label> <br></br>
            <label className={styles.pools}> HTE Pool : {Number(pool.hteBalance).toFixed(2)}</label>
            <select onChange={selectCoinHandler}  > Select your coin
                <option>10 Antex Points - (ANT-P)</option>
                <option>0.2 Hepton Testnet - (HTE)</option>
            </select> <br></br>
            <label>Enter your wallet address</label> <br></br>
            <input onChange={handleWallet} type="text" placeholder="Enter your wallet"></input><button onClick={submitHandler} style={{backgroundColor: error && `red`}}>{
                error ? `Invalid Address` : `Claim`
            }</button> <br></br>
   </form>
  );
}